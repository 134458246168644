import { ApolloError, useReactiveVar } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { currentSongtrustUserPersonaVar } from '~/cache';
import { passwordRegex } from '~/constants/regex';
import { Translator } from '~/types/Translator';
import {
  LoggedInSongtrustUserPersonaQuery,
  UpdateAccountPasswordMutation,
  useUpdateAccountPasswordMutation,
} from '~/types/generated/graphql';
import GeneratePasswordCode from '../GeneratePasswordCode/GeneratePasswordCode';

/**
 * Props for the ChangePasswordSection component
 * @typedef {Object} ChangePasswordSectionProps
 * @property {boolean} [dashboardRedirect] - Whether to redirect to the dashboard after a successful password change
 */
interface ChangePasswordSectionProps extends Translator {
  dashboardRedirect?: boolean;
}

/**
 * ChangePasswordSection component to handle user password changes
 * @param {ChangePasswordSectionProps} props - Props for the component
 * @returns {JSX.Element} ChangePasswordSection component
 */
function ChangePasswordSection({
  t,
  dashboardRedirect = false,
}: ChangePasswordSectionProps): JSX.Element {
  const navigate = useNavigate();
  const loggedInUserPrersona = useReactiveVar(currentSongtrustUserPersonaVar);

  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');
  const [secondStepEnabled, setSecondStepEnabled] = useState<boolean>(false);

  const changePasswordForm = useForm();

  const [updatePassword, { loading }] = useUpdateAccountPasswordMutation({
    fetchPolicy: 'no-cache',
  });

  /**
   * Handles the form submission completion
   * @param {UpdateAccountPasswordMutation} data - Data returned from the mutation
   */
  const handleFormSubmitComplete = (data: UpdateAccountPasswordMutation) => {
    if (data?.changePassword?.errors) {
      if (data.changePassword?.errors?.length > 0) {
        const errorText = data.changePassword?.errors
          .map((error) => error?.error as string)
          .join('<br/>');
        setProcessError(errorText);
        setWasSaved(false);
      } else {
        changePasswordForm.resetField('authcode');
        changePasswordForm.resetField('oldpassword');
        changePasswordForm.resetField('newpassword');
        changePasswordForm.resetField('confirmnewpassword');
        setWasSaved(true);
        window.scrollTo({ top: 0 });

        // Redirect to dashboard if requested and no longer need a password change
        const requiresPasswordChange =
          data.changePassword?.loggedInSongtrustUser?.requiresPasswordChange;

        if (dashboardRedirect && !requiresPasswordChange) {
          const updatedUserPersona = {
            ...loggedInUserPrersona,
            loggedInSongtrustUser: {
              ...loggedInUserPrersona?.loggedInSongtrustUser,
              requiresPasswordChange,
            },
          };

          currentSongtrustUserPersonaVar(
            updatedUserPersona as LoggedInSongtrustUserPersonaQuery,
          );

          setTimeout(() => {
            navigate('/dashboard');
          }, 4000);
        }
      }
    }
  };

  /**
   * Handles the form submission error
   * @param {ApolloError} error - Error object from Apollo
   */
  const handleFormSubmitError = (error: ApolloError) => {
    setProcessError(error.message);
    setWasSaved(false);
  };

  /**
   * Handles form errors
   */
  const handleFormError = async () => {
    setProcessError(t('sections.change-password.errors.fields'));
  };

  /**
   * Handles form submission
   * @param {FieldValues} values - Form values
   */
  const handleFormSubmit = async (values: FieldValues) => {
    setProcessError('');
    if (await changePasswordForm.trigger()) {
      // Full submission variables
      const submissionVariables = {
        oldPassword: values.oldpassword,
        newPassword: values.newpassword,
        authCode: values.authcode,
      };

      // Submit Mutation
      updatePassword({
        variables: submissionVariables,
        onCompleted: handleFormSubmitComplete,
        onError: handleFormSubmitError,
      });
    }
  };

  return (
    <div data-testid="change-password-section">
      <FormProvider {...changePasswordForm}>
        <form
          id="changePasswordForm"
          onSubmit={changePasswordForm.handleSubmit(
            handleFormSubmit,
            handleFormError,
          )}
          onChange={() => {
            setWasSaved(false);
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!wasSaved && (
                <FormBanner text={processError} type={FormBannerType.ERROR} />
              )}
              {wasSaved && (
                <FormBanner
                  text={t('sections.change-password.success')}
                  type={FormBannerType.SUCCESS}
                  time={10000}
                  recall={wasSaved}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography
                data-testid="change-password-section-subtitle1"
                variant="h2"
                component="h2"
              >
                {t('sections.change-password.request-change-code')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <GeneratePasswordCode
                t={t}
                callbackOnSave={setSecondStepEnabled}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: '2rem' }}>
              <Typography
                data-testid="change-password-section-subtitle2"
                variant="h2"
                component="h2"
              >
                {t('sections.change-password.request-change')}
              </Typography>
            </Grid>

            <Grid item xs={12} md="auto">
              <FormInput
                sx={{
                  width: { xs: '100%', md: 'none' },
                }}
                label={t('sections.change-password.fields.oldpassword.label')}
                name="oldpassword"
                id="oldpassword"
                disabled={!secondStepEnabled}
                required={{
                  value: true,
                  message: t(
                    'sections.change-password.fields.oldpassword.required',
                  ),
                }}
                type="password"
              />
            </Grid>
            <Grid item xs={12} md="auto">
              <FormInput
                sx={{
                  width: { xs: '100%', md: 'none' },
                }}
                label={t('sections.change-password.fields.newpassword.label')}
                name="newpassword"
                id="newpassword"
                disabled={!secondStepEnabled}
                required={{
                  value: true,
                  message: t(
                    'sections.change-password.fields.newpassword.required',
                  ),
                }}
                validate={{
                  isComplexPassword: (v) =>
                    passwordRegex.test(v as string) ||
                    (t(
                      'sections.change-password.fields.newpassword.complexity',
                    ) as string),
                }}
                type="password"
              />
            </Grid>

            <Grid item xs={12} md="auto">
              <FormInput
                sx={{
                  width: { xs: '100%', md: 'none' },
                }}
                label={t(
                  'sections.change-password.fields.confirmnewpassword.label',
                )}
                name="confirmnewpassword"
                id="confirmnewpassword"
                required={{
                  value: true,
                  message: t(
                    'sections.change-password.fields.confirmnewpassword.required',
                  ),
                }}
                validate={{
                  isMatchingPassword: (v) =>
                    v === changePasswordForm.getValues('newpassword') ||
                    (t(
                      'sections.change-password.fields.confirmnewpassword.nonmatching',
                    ) as string),
                }}
                disabled={!secondStepEnabled}
                type="password"
              />
            </Grid>

            {secondStepEnabled && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: '1px solid #7E7E7E',
                    p: '1rem',
                  }}
                >
                  {t('sections.change-password.instruction')}
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid item xs={6} md={3}>
                {loading && <LoadingIndicator size={50} />}
                {!loading && (
                  <Button
                    data-testid="update-password-button"
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!secondStepEnabled}
                    sx={{
                      width: '100%',
                      pt: '.5rem',
                      pb: '.5rem',
                      pl: '3rem',
                      pr: '3rem',
                    }}
                  >
                    {!wasSaved && (
                      <>{t('sections.change-password.update-password')}</>
                    )}
                    {wasSaved && (
                      <>
                        {t('sections.change-password.saved')}{' '}
                        <CheckCircleIcon />
                      </>
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
}

export default ChangePasswordSection;
