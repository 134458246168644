/* eslint-disable react/require-default-props */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import FormTooltipAlert from '../FormTooltip/FormTooltipAlert';

const COLOR_DEFAULTS: { [key: string]: string } = {
  yellow: '#FFDF49',
  blue: '#97D7E6',
  green: '#99D35B',
  orange: '#FF8100',
};

interface SectionTipCardProps {
  title: string;
  color?: string;
  children: JSX.Element;
  readMoreLink?: string;
  readMoreText?: string;
  alertContent?: React.ReactNode;
}

function SectionTipCard({
  title,
  children,
  color = 'yellow',
  readMoreLink = '',
  readMoreText = '',
  alertContent = null,
}: SectionTipCardProps) {
  const [open, setOpen] = useState(false);

  /**
   * Handles the tooltip close event.
   */
  const handleTooltipClose = () => {
    setOpen(false);
  };

  /**
   * Handles the tooltip toggle event.
   */
  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div data-testid="dashboard-tip">
        {title !== undefined && children !== undefined && (
          <Grid
            container
            sx={{
              backgroundColor: COLOR_DEFAULTS[color],
              p: '1rem 1rem 2rem 1rem',
            }}
          >
            {title !== '' && (
              <Grid item xs={12}>
                <Typography variant="h5" component="h5">
                  {title}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: '#FFF',
                padding: '.5rem 1rem',
                a: { color: '#D5164B' },
              }}
            >
              {children}
            </Grid>
            {readMoreLink && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" sx={{ pt: 2 }}>
                  <Button size="small" onClick={handleTooltipToggle}>
                    <Typography
                      variant="subtitle2"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {readMoreText}
                      <ChevronRightIcon sx={{ marginLeft: 1 }} />
                    </Typography>
                  </Button>
                  <FormTooltipAlert
                    open={open}
                    onClose={handleTooltipToggle}
                    content={alertContent}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default SectionTipCard;
