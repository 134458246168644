import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { concat, find, kebabCase, map } from 'lodash';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import FormTooltip from '~/base/components/FormTooltip';

export interface FormSelectOption {
  /** Unique identifier for the option */
  choiceId: string;
  /** Display label for the option */
  choiceLabel: string;
  /** Indicates if the option is disabled */
  disabled?: boolean;
}

interface FormSelectProps {
  /** Unique identifier for the select element */
  id: string;
  /** Label for the select element */
  label: string;
  /** Name attribute for the select element */
  name: string;
  /** Tooltip text to provide additional information */
  tooltip?: string;
  /** Custom styles for the FormControl component */
  sx?: SxProps<Theme>;
  /** Custom styles for the Select component */
  inputSx?: SxProps<Theme>;
  /** Whether the select is required */
  required?: boolean;
  /** Type of the select element, default is 'text' */
  type?: string;
  /** Children elements to render inside FormControl */
  children?: React.ReactNode;
  /** Options to display in the select dropdown */
  options: FormSelectOption[];
  /** Pre-selected value for the select element */
  value?: FormSelectOption;
  /** Placeholder text when no option is selected */
  placeholder?: string;
  /** Callback function triggered when an option is selected */
  onOptionSelected?: (event: SelectChangeEvent) => void;
}

/** Default styles for the select input */
const inputDefaultStyle = {
  minWidth: '16.5rem',
  padding: '.5rem',
  border: '1px solid #7E7E7E',
  borderRadius: '.5rem',
};

/**
 * A controlled select component that integrates with react-hook-form.
 * Displays a dropdown list of options and handles selection changes.
 *
 * @param {FormSelectProps} props - The properties for the FormSelect component.
 * @returns {JSX.Element} The rendered FormSelect component.
 */
function FormSelect({
  id,
  name,
  label,
  tooltip,
  sx,
  inputSx,
  required = false,
  type = 'text',
  children,
  options,
  value,
  placeholder = '',
  onOptionSelected,
}: FormSelectProps) {
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const {
    name: fieldName,
    onChange,
    onBlur,
    ref,
  } = register(name, { required });

  const optionsToRender = placeholder
    ? concat(options, {
        choiceId: '',
        choiceLabel: placeholder,
      })
    : options;

  const error = name in errors ? errors[name] : '';
  const currentValue = value ? value.choiceId : getValues(name);

  return (
    <FormControl sx={sx} variant="standard">
      {children}
      <InputLabel htmlFor={id} shrink={false} sx={{ width: '100%', zIndex: 1 }}>
        <Grid container justifyContent="space-between">
          <Grid item>{label}</Grid>
          <Grid item>{tooltip && <FormTooltip tooltip={tooltip} />}</Grid>
        </Grid>
      </InputLabel>
      <Select
        displayEmpty
        disableUnderline
        id={id}
        data-testid={kebabCase(id)}
        aria-describedby={label}
        error={!!error}
        onChange={(event) => {
          if (onOptionSelected) {
            onOptionSelected(event);
          }
          setValue(name, event.target.value);
          return onChange(event);
        }}
        ref={ref}
        onBlur={onBlur}
        type={type}
        name={fieldName}
        value={currentValue}
        renderValue={() => {
          const element = find(
            optionsToRender,
            (item) =>
              ((item.choiceId as string) || '').toLowerCase() ===
              ((getValues(name) as string) || '').toLowerCase(),
          );
          return element?.choiceLabel;
        }}
        sx={{ ...inputDefaultStyle, ...inputSx }}
        endAdornment={
          error ? (
            <InputAdornment data-testid={`${id}-error`} position="end">
              <ErrorOutlineIcon sx={{ color: '#F33126' }} />
            </InputAdornment>
          ) : null
        }
      >
        {map(optionsToRender, ({ choiceId, choiceLabel, disabled }) => (
          <MenuItem
            dense
            selected={choiceId === currentValue}
            key={choiceId}
            value={choiceId}
            disabled={disabled}
          >
            {choiceLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default FormSelect;
