/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloError } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import {
  FieldValues,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import FormSelect from '~/base/components/FormSelect';
import FormTooltip from '~/base/components/FormTooltip';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  CreateOutsideSongwriterMutation,
  OutsideSongwriterInput,
  PublishingCompanyInput,
  useCreateOutsideSongwriterMutation,
  usePerformingRightsOrganizationsQuery,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

type AddOutsideSongwriterPageProps = Translator;
function AddOutsideSongwriterPage({ t }: AddOutsideSongwriterPageProps) {
  /** State */
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');
  const [publisherOpen, setPublisherOpen] = useState(false);

  /** Hooks */
  const navigate = useNavigate();
  const formMethods = useForm();
  const {
    fields: alternateNameFields,
    append,
    remove,
  } = useFieldArray({
    name: 'alternate-names',
    control: formMethods.control,
  });

  /** Mutations/Queries */
  const { data: proData } = usePerformingRightsOrganizationsQuery();
  const [createOutsideSongwriter, { loading }] =
    useCreateOutsideSongwriterMutation({
      fetchPolicy: 'no-cache',
    });

  /**
   * Navigates the user back to the '/songwriters/manage/outside' route.
   */
  const handleBack = () => {
    navigate('/songwriters/manage/outside');
  };

  /**
   * Handles errors that occur during form submission.
   * If an error is provided, scrolls the window to the top.
   * @param {ApolloError | undefined} inError The error object, if any, encountered during submission.
   */
  const handleSubmitError = (inError: ApolloError | undefined) => {
    if (inError) {
      // If an error occurred, scroll to the top of the window
      window.scrollTo({ top: 0 });
      setProcessError(inError.message);
      setWasSaved(false);
    }
  };

  /**
   * Sets an error message and updates the saved state of the form to false.
   * This function is typically used to handle errors encountered.
   */
  const handleFormError = async () => {
    setProcessError(t('page.add-outside-songwriter.form.errors.fields'));
    setWasSaved(false);
  };

  /**
   * Handles the completion of form submission.
   * If there are errors in the submission data, processes them using handleFormError.
   * Otherwise, sets the form state to indicate successful saving and navigates to '/songwriters/manage'.
   * @param {CreateOutsideSongwriterMutation} data The result data from the createSongwriter mutation.
   */
  const handleSubmitComplete = (data: CreateOutsideSongwriterMutation) => {
    if (data?.createOutsideSongwriter?.songwriter === undefined) {
      handleFormError();
    } else {
      setWasSaved(true);
      navigate('/songwriters/manage/outside');
    }
  };

  /**
   * Handles form submission asynchronously.
   * If publisherOpen is false, hides the publishingCompany field in the form values.
   * Calls createSongwriter mutation with the modified variables.
   * Logs any submission error to the console.
   * @param {FieldValues} values The form values containing input data.
   */
  const handleSubmit = async (values: FieldValues) => {
    const publishingCompanyInput: PublishingCompanyInput = {
      name: values['songwriter-publisher-name'],
      ipi: values['songwriter-publisher-ipi'],
      email: values['songwriter-publisher-email'],
    };

    const proInput = {
      name: values.pro,
    };

    const songwriterInput: OutsideSongwriterInput = {
      songwriterType: 'outside',
      email: values.email.length > 0 ? values.email : undefined,
      firstName: values.firstname,
      middleName: values.middlename,
      lastName: values.lastname,
      alternateNames: values['alternate-names'].map(
        (obj: { name: string }) => obj.name,
      ),
      pro: proInput.name ? proInput : undefined,
      ipi: values.ipi.length > 0 ? values.ipi : undefined,
      songwriterYoutubeClaims: true, // This is an OPT OUT of youtube claims. So should be TRUE for all OutsideSongwriters, probably needs a better name.
      publishingCompany: publisherOpen ? publishingCompanyInput : undefined,
    };

    try {
      await createOutsideSongwriter({
        variables: {
          input: songwriterInput,
        },
        onCompleted: handleSubmitComplete,
        onError: handleSubmitError,
      });
    } catch (error) {
      handleFormError();
    }
  };

  /** Render */
  return (
    <Container data-testid="add-outside-songwriter-page">
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: {
            xs: 'column',
          },
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h1" component="h1" sx={{ pt: { md: '2rem' } }}>
            {t('page.add-outside-songwriter.title')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {!wasSaved && (
            <FormBanner text={processError} type={FormBannerType.ERROR} />
          )}
          {wasSaved && (
            <FormBanner
              text={t('page.add-outside-songwriter.form.success')}
              type={FormBannerType.SUCCESS}
              time={2000}
              recall={wasSaved}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <FormProvider {...formMethods}>
              {/* Songwriter Basic Information */}
              <Grid
                container
                item
                direction="column"
                xs={12}
                md={7}
                gap={2}
                margin={{ xs: 'auto', md: '1rem' }}
              >
                <FormInput
                  id="email"
                  data-testid="email"
                  name="email"
                  type="email"
                  label={t(
                    'page.add-outside-songwriter.form.fields.email.label',
                  )}
                  placeholder={t(
                    'page.add-outside-songwriter.form.fields.email.placeholder',
                  )}
                  required={{
                    value: false,
                    message: t(
                      'page.add-outside-songwriter.form.fields.email.validation.required',
                    ),
                  }}
                />
                <FormInput
                  id="firstname"
                  data-testid="firstname"
                  name="firstname"
                  label={t(
                    'page.add-outside-songwriter.form.fields.firstname.label',
                  )}
                  placeholder={t(
                    'page.add-outside-songwriter.form.fields.firstname.placeholder',
                  )}
                  type="text"
                  required={{
                    value: true,
                    message: t(
                      'page.add-outside-songwriter.form.fields.firstname.validation.required',
                    ),
                  }}
                />
                <FormInput
                  id="middlename"
                  data-testid="middlename"
                  name="middlename"
                  label={t(
                    'page.add-outside-songwriter.form.fields.middlename.label',
                  )}
                  type="text"
                  placeholder={t(
                    'page.add-outside-songwriter.form.fields.middlename.placeholder',
                  )}
                  required={{
                    value: false,
                    message: t(
                      'page.add-outside-songwriter.form.fields.middlename.validation.required',
                    ),
                  }}
                />
                <FormInput
                  id="lastname"
                  data-testid="lastname"
                  name="lastname"
                  label={t(
                    'page.add-outside-songwriter.form.fields.lastname.label',
                  )}
                  placeholder={t(
                    'page.add-outside-songwriter.form.fields.lastname.placeholder',
                  )}
                  type="text"
                  required={{
                    value: true,
                    message: t(
                      'page.add-outside-songwriter.form.fields.lastname.validation.required',
                    ),
                  }}
                />
              </Grid>

              {/* Alternate Names Array */}
              <Grid
                container
                item
                direction="column"
                xs={12}
                md={7}
                gap={2}
                margin={{ xs: '1rem auto', md: '1rem' }}
              >
                {alternateNameFields.map((field, i) => {
                  return (
                    <Grid key={field.id} container item xs={12} direction="row">
                      <FormInput
                        id={`alternate-name-${field.id}`}
                        name={`alternate-names.${i}.name`}
                        label={t(
                          'page.add-outside-songwriter.form.fields.alternate-name.label',
                        )}
                        sx={{ flexGrow: '1' }}
                        inputProps={{
                          'data-testid': `alternate-name-${i}`,
                        }}
                        inputFullWidth
                      />
                      <IconButton
                        data-testid={`alternate-name-close-${i}`}
                        onClick={() => remove(i)}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  );
                })}
              </Grid>

              {/* Alternate Names Adder Button */}
              <Grid
                container
                item
                sx={{ width: 'max-content', py: 2, ml: { md: 2 } }}
              >
                <Button
                  data-testid="add-alternate-name"
                  variant="contained"
                  startIcon={<AddIcon />}
                  color="success"
                  onClick={() => {
                    append('');
                  }}
                >
                  {t(
                    'page.add-outside-songwriter.form.buttons.add-alternate-name',
                  )}
                </Button>
                <FormTooltip
                  tooltip={t(
                    'page.add-outside-songwriter.form.fields.alternate-name.tooltip',
                  )}
                />
              </Grid>

              {/* PRO and IPI Information */}
              <Grid
                container
                item
                direction="column"
                xs={12}
                md={7}
                gap={2}
                margin={{ xs: 'auto', md: '1rem' }}
              >
                <FormSelect
                  id="pro"
                  data-testid="pro"
                  name="pro"
                  label={t('page.add-outside-songwriter.form.fields.pro.label')}
                  placeholder={t(
                    'page.add-outside-songwriter.form.fields.pro.placeholder',
                  )}
                  options={
                    proData?.performingRightsOrganizations?.edges?.map(
                      (edge) => ({
                        choiceId: edge?.node?.id || '',
                        choiceLabel: edge?.node?.name || '',
                      }),
                    ) || []
                  }
                  tooltip={t(
                    'page.add-outside-songwriter.form.fields.pro.tooltip',
                  )}
                />
                <FormInput
                  id="ipi"
                  data-testid="ipi"
                  name="ipi"
                  label={t('page.add-outside-songwriter.form.fields.ipi.label')}
                  required={{
                    value: false,
                    message: t(
                      'page.add-outside-songwriter.form.fields.ipi.validation.required',
                    ),
                  }}
                  tooltip={t(
                    'page.add-outside-songwriter.form.fields.ipi.tooltip',
                  )}
                  placeholder={t(
                    'page.add-outside-songwriter.form.fields.ipi.placeholder',
                  )}
                />
              </Grid>

              {/* Publisher Information */}
              <Collapse in={publisherOpen} collapsedSize={0}>
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  md={7}
                  gap={2}
                  margin={{ xs: '1rem auto', md: '1rem' }}
                >
                  <FormInput
                    id="songwriter-publisher-name"
                    data-testid="songwriter-publisher-name"
                    name="songwriter-publisher-name"
                    label={t(
                      'page.add-outside-songwriter.form.fields.songwriter-publisher-name.label',
                    )}
                    placeholder={t(
                      'page.add-outside-songwriter.form.fields.songwriter-publisher-name.placeholder',
                    )}
                    required={{
                      value: publisherOpen,
                      message: t(
                        'page.add-outside-songwriter.form.fields.songwriter-publisher-name.validation.required',
                      ),
                    }}
                  />
                  <FormInput
                    id="songwriter-publisher-ipi"
                    data-testid="songwriter-publisher-ipi"
                    name="songwriter-publisher-ipi"
                    label={t(
                      'page.add-outside-songwriter.form.fields.songwriter-publisher-ipi.label',
                    )}
                    placeholder={t(
                      'page.add-outside-songwriter.form.fields.songwriter-publisher-ipi.placeholder',
                    )}
                    required={{
                      value: publisherOpen,
                      message: t(
                        'page.add-outside-songwriter.form.fields.songwriter-publisher-ipi.validation.required',
                      ),
                    }}
                  />
                  <FormInput
                    id="songwriter-publisher-email"
                    data-testid="songwriter-publisher-email"
                    name="songwriter-publisher-email"
                    label={t(
                      'page.add-outside-songwriter.form.fields.songwriter-publisher-email.label',
                    )}
                    placeholder={t(
                      'page.add-outside-songwriter.form.fields.songwriter-publisher-email.placeholder',
                    )}
                  />
                </Grid>
              </Collapse>

              {/* Publisher Toggle */}
              <Grid
                container
                item
                sx={{ width: 'max-content', py: 2, ml: { md: 2 } }}
              >
                <Button
                  data-testid="toggle-publisher"
                  variant={publisherOpen ? 'outlined' : 'contained'}
                  startIcon={publisherOpen ? <RemoveIcon /> : <AddIcon />}
                  color={publisherOpen ? 'inherit' : 'success'}
                  onClick={() => setPublisherOpen(!publisherOpen)}
                >
                  {t(
                    `page.add-outside-songwriter.form.buttons.${
                      publisherOpen
                        ? 'outside-songwriter-no-publisher'
                        : 'outside-songwriter-publisher'
                    }`,
                  )}
                </Button>
              </Grid>

              {/* Action Buttons */}
              <Grid item xs={12} md={8}>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    data-testid="button-back"
                    sx={{ mr: 1 }}
                    onClick={handleBack}
                  >
                    {t('page.add-outside-songwriter.form.buttons.back')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {loading && <LoadingIndicator size={35} />}
                  {!loading && (
                    <Button
                      variant="contained"
                      color="secondary"
                      data-testid="button-submit"
                      type="submit"
                      name="button-submit"
                    >
                      {t('page.add-outside-songwriter.form.buttons.submit')}
                    </Button>
                  )}
                </Box>
              </Grid>
            </FormProvider>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddOutsideSongwriterPage;
