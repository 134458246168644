import {
  Button,
  FormControl,
  FormHelperText,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import FormInput from '~/base/components/FormInput';
import {
  FieldError,
  ForgotPasswordMutation,
  useForgotPasswordMutation,
} from '~/types/generated/graphql';

interface ForgotPassswordPageFormProps {
  setResetSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

function ForgotPasswordPageForm({
  setResetSuccess,
}: ForgotPassswordPageFormProps) {
  const { t } = useTranslation(['forgotpassword']);

  const navigate = useNavigate();

  const formMethods = useFormContext();
  const { setError } = useFormContext();

  const [resetPassword, { loading, error }] = useForgotPasswordMutation({
    fetchPolicy: 'network-only',
  });

  const forgotPasswordClickHandler = (values: FieldValues) => {
    resetPassword({
      variables: { username: values.email },
      onCompleted: (data: ForgotPasswordMutation) => {
        if (data.resetPassword) {
          // Handle field errors sent back from account creation mutation, if any.
          const errors = data.resetPassword.errors as Array<FieldError>;

          if (errors.length > 0) {
            setResetSuccess(false);
            errors.forEach((e: FieldError) => {
              const fieldName = e.path[0] as string;
              const errorText = e.error as string;

              setError(fieldName, {
                message: errorText,
              });
            });
          } else {
            setResetSuccess(true);
          }
        }
      },
    });
  };

  const loginTextFieldStyle = {
    marginBottom: '2rem',
    '& .MuiOutlinedInput-root': {
      marginTop: '1rem',
      '& > fieldset': {
        border: '2px solid #7E7E7E',
        borderRadius: '.5rem',
      },
      '&.Mui-focused, &:hover': {
        '& > fieldset': {
          borderColor: '#031C9B',
        },
      },
    },
  };

  return (
    <div
      className="forgotpassword-page-form"
      data-testid="forgotpassword-page-form"
    >
      <form onSubmit={formMethods.handleSubmit(forgotPasswordClickHandler)}>
        <Stack direction="column">
          <Typography
            variant="h1"
            component="h2"
            sx={{ textAlign: 'center', marginBottom: '2rem' }}
          >
            {t('form.title')}
          </Typography>

          <FormInput
            sx={{
              width: '70%',
              marginLeft: 'auto',
              marginRight: 'auto',
              ...loginTextFieldStyle,
            }}
            id="reset-username"
            name="email"
            label={t('form.email')}
            required={{
              value: true,
              message: t('form.validation.email.required'),
            }}
            validate={{
              isEmailValid: (v) =>
                validator.isEmail(v as string) ||
                t('form.validation.email.invalid'),
            }}
          />

          <FormControl
            sx={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Button
              data-testid="reset-button"
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              {t('form.reset')}
            </Button>
            <FormHelperText
              sx={{
                color: error ? '#F33126' : 'inherit',
                fontSize: '.75rem',
                textAlign: 'center',
              }}
            >
              {loading ? <span>Loading...</span> : null}
              {error ? (
                <span data-testid="login-error-message">
                  Oh no! {error.message}
                </span>
              ) : null}
            </FormHelperText>
            <Link
              component="a"
              href="/login"
              data-testid="return-to-login-link"
              sx={{
                marginTop: '1rem',
                textAlign: 'center',
              }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                navigate('/login');
              }}
            >
              {t('form.back')}
            </Link>
          </FormControl>
        </Stack>
      </form>
    </div>
  );
}

export default ForgotPasswordPageForm;
