import { ApolloError } from '@apollo/client';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '~/base/components/Breadcrumbs/Breadcrumbs';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import RichTooltipContent from '~/base/components/RichTooltipContent';
import SectionTipCard from '~/base/components/SectionTipCard';
import usePageTitle from '~/hooks/usePageTitle';
import {
  SongType,
  UpdateSongInput,
  useSongByIdQuery,
  useSongUpdateMutation,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import ISWC from '../ISWC';
import Lyrics from '../Lyrics/Lyrics';
import RecordingsList from '../Recordings/RecordingsList';
import RegistrationStatus from '../RegistrationStatus/RegistrationStatus';
import SongInformation from '../SongInformation/SongInformation';
import SongwritersTable from '../Songwriters/SongwritersTable';

function EditSongPage({ t }: Translator) {
  // State vars.
  const [currentSong, setCurrentSong] = useState<SongType | undefined>(
    undefined,
  );

  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');
  const [isEditable, setIsEditable] = useState<boolean>(true);

  // Tooltip content loop for bullets (Lyrics).
  const lyricGuidelines = [] as Array<string>;
  const LYRIC_GUIDELINE_COUNT = 14;
  for (let i = 0; i < LYRIC_GUIDELINE_COUNT; i += 1) {
    lyricGuidelines.push(t(`form.tips.lyrics.expanded-content.${i}`));
  }

  // Tooltip content loop for bullets (Alt Titles).
  const altTitleGuidelines = [] as Array<string>;
  const ALT_TITLE_GUIDELINE_COUNT = 4;
  for (let i = 1; i <= ALT_TITLE_GUIDELINE_COUNT; i += 1) {
    altTitleGuidelines.push(
      t(`form.tips.alternate-titles.expanded-content.${i}`),
    );
  }

  // Hooks
  const navigate = useNavigate();

  // Get song ID from URL.
  const { id: songId } = useParams();

  // Query hook for song data.
  const { loading } = useSongByIdQuery({
    variables: {
      id: parseInt(songId || '0', 10),
    },
    onCompleted: (res) => {
      setCurrentSong(res.song as SongType);

      if (
        res.song?.displayStatusLog?.sent ||
        res.song?.displayStatusLog?.registering
      ) {
        setIsEditable(false);
      }
    },
  });

  // React-hook-form
  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      return currentSong || {};
    }, [currentSong]),
    shouldUseNativeValidation: true,
  });

  // Mutation hook handlers.
  const handleSubmitComplete = async () => {
    setWasSaved(true);
    window.scrollTo({ top: 0 });
  };

  const handleSubmitError = (error: ApolloError) => {
    setProcessError(error.message);
  };

  // Mutation hook.
  const [submitSongUpdate, { loading: isUpdatingSong }] = useSongUpdateMutation(
    {
      fetchPolicy: 'network-only',
      onError: handleSubmitError,
      onCompleted: handleSubmitComplete,
    },
  );

  // Form handlers.
  const handleFormError = async () => {
    setProcessError(t('form.errors.fields'));
    setWasSaved(false);
  };

  const handleFormSubmit = async (values: FieldValues) => {
    setProcessError('');
    setWasSaved(false);

    // Trigger react-hook-form fields for validation.
    if (await formMethods.trigger()) {
      if (currentSong && currentSong.id) {
        const songUpdate = {
          songId: parseInt(currentSong?.id, 10),
          title: values.title,
          iswc: values.iswc,
          chorusLyrics: values.chorusLyrics,
          fullLyrics: values.fullLyrics,
          language: currentSong.language,
        } as UpdateSongInput;

        submitSongUpdate({
          variables: {
            song: songUpdate,
          },
          onCompleted: handleSubmitComplete,
          onError: handleSubmitError,
        });
      }
    } else {
      setProcessError(t('form.errors.fields'));
    }
  };

  const handleRemoveRecording = (recordingId: number) => {
    setCurrentSong((prev) => {
      if (!prev) {
        return undefined;
      }
      return {
        ...prev,
        recordings: {
          edges: prev.recordings.edges.filter(
            (recordings) => recordings?.node?.id !== recordingId.toString(),
          ),
          pageInfo: prev.recordings.pageInfo,
          totalCount: prev.recordings.totalCount
            ? prev.recordings.totalCount - 1
            : 0,
        },
      };
    });
  };

  usePageTitle('Edit Song');
  useEffect(() => {
    if (currentSong?.title) {
      document.title = document.title.replace(
        'Edit Song',
        `Edit Song | ${currentSong.title}`,
      );
    }
    formMethods.reset(currentSong);
  }, [currentSong]);

  return (
    <form
      data-testid="edit-song-page"
      onSubmit={formMethods.handleSubmit(handleFormSubmit, handleFormError)}
    >
      <FormProvider {...formMethods}>
        <Container sx={{ flexGrow: 1 }}>
          <Breadcrumbs
            currentPage={loading ? '...' : currentSong?.title}
            breadcrumb={t('page.titles.breadcrumb')}
            breadcrumbLink="/songs/manage"
            testid="song-detail-back-link"
          />
          <Grid item xs={12}>
            <Typography
              data-testid="song-detail-page-title"
              variant="h1"
              component="h1"
            >
              {currentSong?.title}
            </Typography>
          </Grid>
          {/* Body */}
          <Grid container>
            <Grid item xs={12}>
              {!wasSaved && (
                <FormBanner text={processError} type={FormBannerType.ERROR} />
              )}
              {wasSaved && (
                <FormBanner
                  text={t('form.success.song-updated')}
                  type={FormBannerType.SUCCESS}
                  time={2000}
                  recall={wasSaved}
                />
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                {!loading && currentSong?.recordings?.totalCount === 0 && (
                  <FormBanner
                    title={t('form.warnings.add-recording.title')}
                    text={t('form.warnings.add-recording.copy')}
                    type={FormBannerType.WARNING}
                  />
                )}
                <SongInformation
                  t={t}
                  loading={loading}
                  isEditable={isEditable}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.alternate-titles.title')}
                color="blue"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.alternate-titles.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.alternate-titles.title')}
                    type="ol"
                    contents={altTitleGuidelines}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.alternate-titles.content') as string,
                  }}
                />
              </SectionTipCard>
            </Grid>
          </Grid>

          {/* Songwriters */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <SongwritersTable t={t} ownerships={currentSong?.ownerships} />
              </Box>
            </Grid>
          </Grid>

          {/* Registration Status */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <RegistrationStatus t={t} song={currentSong} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }} />
          </Grid>

          {/* Recordings */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <RecordingsList
                  t={t}
                  song={currentSong}
                  handleRemoveRecording={handleRemoveRecording}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }} />
          </Grid>

          {/* ISWC, Lyrics, Chrous Lyrics */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <ISWC t={t} isEditable={isEditable} /> {/* here */}
                <Lyrics t={t} /> {/* here */}
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }}>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.iswc.title')}
                color="orange"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.iswc.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.iswc.title')}
                    contents={[t('form.tips.iswc.content')]}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.iswc.content') as string,
                  }}
                />
              </SectionTipCard>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.lyrics.title')}
                color="green"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.lyrics.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.lyrics.expanded-title')}
                    type="ol"
                    contents={lyricGuidelines}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.lyrics.content') as string,
                  }}
                />
              </SectionTipCard>
            </Grid>
          </Grid>

          {/* Save Button */}
          <Grid
            container
            item
            xs={12}
            md={9}
            sx={{ justifyContent: 'space-between', pr: { md: 3 }, mt: 2 }}
          >
            <Button
              variant="outlined"
              sx={{
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
              onClick={() => {
                // go back to the previous page
                navigate('/songs/manage');
              }}
            >
              {t('form.buttons.cancel')}
            </Button>
            <Button
              data-testid="song-form-submit"
              type="submit"
              variant="contained"
              disabled={!formMethods.formState.isValid || isUpdatingSong}
              sx={{
                backgroundColor: 'secondary.main',
                color: 'text.primary',
                '&:hover': {
                  color: 'white',
                },
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
            >
              {loading ? t('form.loading') : t('form.buttons.update')}
            </Button>
          </Grid>
        </Container>
      </FormProvider>
    </form>
  );
}

export default EditSongPage;
