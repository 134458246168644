/* eslint-disable dot-notation */
import { useReactiveVar } from '@apollo/client';
import { Box, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import accountConstants from '~/account/constants/accountConstants';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { currentSongtrustUserSubscriptionPlanVar } from '~/cache';
import { Translator } from '~/types/Translator';

interface AccountTypeSectionProps extends Translator {
  loading: boolean;
}

function AccountTypeSection({ t, loading }: AccountTypeSectionProps) {
  const accountTypeData = useReactiveVar(
    currentSongtrustUserSubscriptionPlanVar,
  );

  const defaultCurrency =
    `${accountTypeData?.loggedInSongtrustUser?.subscriptionPlan?.planCurrency}` as string;

  const defaultCurrencySymbol = get(
    accountConstants?.currencySymbols,
    defaultCurrency,
  );

  return (
    <div data-testid="account-type-section">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            data-testid="account-type-title"
            variant="h1"
            component="h1"
            sx={{ borderBottom: '1px solid #000' }}
          >
            {t('sections.account-information.account-type.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          {loading && <LoadingIndicator size={50} />}
          {!loading && (
            <Box sx={{ border: '1px solid #000', p: '.5rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {
                    accountTypeData?.loggedInSongtrustUser?.subscriptionPlan
                      ?.name
                  }
                </Grid>
                <Grid item xs={6}>
                  <span data-testid="account-type-cardinfo">
                    {accountTypeData?.loggedInSongtrustUser?.billingInformation
                      ?.last4
                      ? `${t(
                          'sections.account-information.account-type.card-ending',
                        )}${
                          accountTypeData?.loggedInSongtrustUser
                            ?.billingInformation?.last4
                        } [${
                          accountTypeData?.loggedInSongtrustUser
                            ?.billingInformation?.expMonth
                        }/${
                          accountTypeData?.loggedInSongtrustUser
                            ?.billingInformation?.expYear
                        }]`
                      : t('sections.account-information.account-type.no-card')}
                  </span>
                  <br />
                  <span data-testid="account-type-songinfo">
                    {t(
                      'sections.account-information.account-type.unlimited-songs',
                    )}
                  </span>
                  <br />
                  <span data-testid="account-type-comission">
                    {`${
                      accountTypeData?.loggedInSongtrustUser?.subscriptionPlan
                        ?.commissionRate
                    }${t(
                      'sections.account-information.account-type.percent-commission',
                    )}`}
                  </span>
                  <br />
                  <span data-testid="account-type-memberinfo">
                    {`${accountTypeData?.loggedInSongtrustUser?.subscriptionPlan?.memberCount} ${accountTypeData?.loggedInSongtrustUser?.subscriptionPlan?.memberLabel}`}
                  </span>
                </Grid>
                <Grid item xs={6} sx={{ position: 'relative' }}>
                  <Typography
                    data-testid="account-type-title"
                    variant="h2"
                    component="h3"
                    sx={{
                      bottom: '.5rem',
                      right: '1rem',
                      position: 'absolute',
                    }}
                  >
                    {`${defaultCurrencySymbol} ${accountTypeData?.loggedInSongtrustUser?.subscriptionPlan?.planCost} / ${accountTypeData?.loggedInSongtrustUser?.subscriptionPlan?.planFrequency}`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AccountTypeSection;
