/* eslint-disable camelcase */
import { ApolloError, useReactiveVar } from '@apollo/client';
import { Grid, Link, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  currentUserDisplayNameVar,
  toRemoveVar,
  toUpsertVar,
} from '~/account/state/state';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import {
  SongwriterAccessInput,
  TargetAccountInput,
  useSubmitSongwriterAccessToAccountMutation,
} from '~/types/generated/graphql';
import AccessManagementInvitePageActions from '../AccessManagementInvitePageActions/AccessManagementInvitePageActions';
import AccessManagementInvitePageInput from '../AccessManagementInvitePageInput/AccessManagementInvitePageInput';
import AccessManagementInvitePageTips from '../AccessManagementInvitePageTips/AccessManagementInvitePageTips';
import AccessManagementSongwriterList from '../AccessManagementSongwriterList';

interface AccessManagementInvitePageProps extends Translator {
  editMode: boolean;
}

function AccessManagementInvitePage({
  t,
  editMode,
}: AccessManagementInvitePageProps) {
  usePageTitle(
    `${t('sections.access-management-invite.title')} ${t(
      'sections.access-management-invite.title-invite',
    )}`,
  );

  const formMethods = useForm();
  const { id: editSongtrustUserId } = useParams();

  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');

  const currentUserDisplayName = useReactiveVar(currentUserDisplayNameVar);
  const toUpsert = useReactiveVar(toUpsertVar);
  const toRemove = useReactiveVar(toRemoveVar);

  // Mutation hook handlers.
  const handleSubmitComplete = () => {
    setWasSaved(true);
    window.scrollTo({ top: 0 });
  };

  const handleSubmitError = (inError: ApolloError | undefined) => {
    if (inError) {
      setProcessError(inError.message);
      setWasSaved(false);
      window.scrollTo({ top: 0 });
    }
  };

  // Mutation hook.
  const [submitSongwriterAccess, { loading: submitLoading }] =
    useSubmitSongwriterAccessToAccountMutation({
      fetchPolicy: 'network-only',
      onError: handleSubmitError,
      onCompleted: handleSubmitComplete,
    });

  // Form handlers.
  const handleFormError = async () => {
    setProcessError(t('sections.access-management-invite.form.errors.fields'));
    setWasSaved(false);
  };

  const handleFormSubmit = async () => {
    setProcessError('');
    setWasSaved(false);

    if (await formMethods.trigger()) {
      const recipientEmail = formMethods.getValues('invite-email');
      const recipientSongtrustUserId =
        formMethods.getValues('songtrust-userid');

      const targetAccount = {
        email: recipientEmail,
        songtrustUserId: recipientSongtrustUserId
          ? parseInt(recipientSongtrustUserId, 10)
          : undefined,
      } as TargetAccountInput;

      // Remove one or the other based on if we are editing or inviting.
      if (!editMode || recipientSongtrustUserId === undefined)
        delete targetAccount.songtrustUserId;
      else delete targetAccount.email;

      const access: SongwriterAccessInput = {
        toUpsert,
        toRemove,
      };

      // No removals for "non-edit" insert only mode (invite).
      if (!editMode) access.toRemove = [];

      // Now send it.
      submitSongwriterAccess({
        variables: {
          targetAccount,
          access,
        },
        onCompleted: handleSubmitComplete,
        onError: handleSubmitError,
      });
    }
  };

  // Page Load.
  useEffect(() => {
    if (editMode) {
      formMethods.setValue('songtrust-userid', editSongtrustUserId);
    }

    // Reset Shared State Vars.
    currentUserDisplayNameVar('');
    toUpsertVar([]);
    toRemoveVar([]);

    // Scroll to top.
    window.scrollTo({ top: 0 });
  }, []);

  // Render.
  return (
    <div data-testid="access-management-invite-page">
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleFormSubmit, handleFormError)}
          onChange={() => {
            setWasSaved(false);
          }}
        >
          <Container>
            <Grid
              container
              spacing={2}
              sx={{
                flexDirection: {
                  xs: 'column',
                },
              }}
            >
              <Grid item xs={12} md={12}>
                <Typography variant="body1" component="p" sx={{ pt: '1rem' }}>
                  <Link to="/account/access-management" component={RouterLink}>
                    {t('sections.access-management-invite.breadcrumb')}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography
                  data-testid="access-management-invite-page-title"
                  variant="h1"
                  component="h1"
                >
                  {!editMode &&
                    `${t('sections.access-management-invite.title')} ${t(
                      'sections.access-management-invite.title-invite',
                    )}`}

                  {editMode && t('sections.access-management-invite.title')}
                  {editMode && currentUserDisplayName && (
                    <span>/ {currentUserDisplayName}</span>
                  )}
                </Typography>
                <Typography
                  data-testid="access-management-invite-page-subtitle"
                  variant="body1"
                  component="p"
                >
                  {!editMode && t('sections.access-management-invite.subtitle')}
                  {editMode &&
                    t('sections.access-management-invite.subtitle-edit')}
                </Typography>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                }}
              >
                <Grid item xs={12}>
                  <Box
                    sx={{
                      pl: { xs: '1rem', md: '2rem' },
                      pt: '2rem',
                    }}
                  >
                    {!wasSaved && (
                      <FormBanner
                        text={processError}
                        type={FormBannerType.ERROR}
                      />
                    )}
                    {wasSaved && (
                      <FormBanner
                        text={t(
                          'sections.access-management-invite.form.success',
                        )}
                        type={FormBannerType.SUCCESS}
                        time={2000}
                        recall={wasSaved}
                      />
                    )}
                  </Box>
                </Grid>

                {!editMode && (
                  <Grid item xs={12}>
                    <AccessManagementInvitePageInput
                      t={t}
                      formMethods={formMethods}
                    />
                  </Grid>
                )}

                <Grid item xs={12} md={9}>
                  <Box
                    sx={{
                      pl: { xs: '1rem', md: '2rem' },
                      pr: { xs: '1rem', md: '4rem' },
                      pt: { xs: '2rem', md: '0' },
                    }}
                  >
                    <AccessManagementSongwriterList
                      t={t}
                      editSongtrustUserId={
                        editSongtrustUserId !== undefined
                          ? parseInt(editSongtrustUserId, 10)
                          : undefined
                      }
                      formMethods={formMethods}
                    />
                    <AccessManagementInvitePageActions
                      t={t}
                      editMode={editMode}
                      submitLoading={submitLoading}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Box
                    sx={{
                      pl: { xs: '1rem', md: 0 },
                    }}
                  >
                    <AccessManagementInvitePageTips t={t} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormProvider>
    </div>
  );
}

export default AccessManagementInvitePage;
