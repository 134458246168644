/* eslint-disable react/require-default-props */
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';

type BreadcrumbProps = {
  currentPage: string | undefined;
  breadcrumb?: string | undefined;
  breadcrumbLink?: string | undefined;
  testid?: string | undefined;
};

function Breadcrumb({
  currentPage = '',
  breadcrumb = undefined,
  breadcrumbLink = undefined,
  testid = '',
}: BreadcrumbProps) {
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Breadcrumbs
          separator=">"
          aria-label="breadcrumb"
          component="p"
          sx={{ pt: '1rem', color: '#D5164B' }}
        >
          <Link
            underline="hover"
            component="a"
            data-testid={testid}
            href={breadcrumbLink}
          >
            {breadcrumb}
          </Link>
          <Typography>{currentPage}</Typography>
        </Breadcrumbs>
      </Grid>
    </Grid>
  );
}

export default Breadcrumb;
